<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Lending component
 */
export default {
  page: {
    title: "Bank Account Verification",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, simplebar },
  data() {
    return {
      title: "Bank Account Verification",
      items: [
        {
          text: "Bank Account",
          href: "/admin",
        },
        {
          text: "Bank Account",
          active: true,
        },
      ],
      loading: false,
      remarkShow: false,
      form: {
        status: "",
        remark: "",
      },
      totalRows: 0,
      currentRowData: "",
      currentPage: 1,
      perPage: 10,
      sortBy: "value",
      sortDesc: false,
      submitted: false,
     fields: [
        // {
        //   key: "id",
        //   value: "id",
        //   sortable: true,
        // },
        {
          key: "Name",
          value: "name",
          sortable: true,
        },
        {
          key: "Username",
          sortable: true,
        },
        {
          key: "Email",
          value: "Email",
          sortable: true,
        },
        {
          key: "Mobile",
          sortable: true,
        },
        {
          key: "Document",
          sortable: true,
        },
        
      ],
    };
  },
  created() {
    this.myProvider.bind(this);
  },
  validations: {
    form: {
      status: { required },
      remark: {
        required: requiredIf(function () {
          return this.form.status == 0 ? true : false;
        }),
      },
    },
  },
  methods: {
    async myProvider(ctx, callback) {
      this.loading = !this.loading;
      await ApiClass.getRequest(
        "admin/userbanks/get?status=rejected&per_page=" +
          ctx.perPage +
          "&page=" +
          ctx.currentPage,
        true
      ).then((res) => {
        if (res.data.status_code == 1) {
          this.loading = !this.loading;
          const items = res.data.data ? res.data.data.data : [];
          this.currentPage = res.data.data ? res.data.data.current_page : "";
          this.totalRows = res.data.data ? res.data.data.total : "";

          callback(items);
        }
      }).bind(this);
    },
    async onSubmit(event, id) {
      event.preventDefault();

      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }

      var formdata = {
        is_verify: this.form.status,
        remark: this.form.remark,
      };

      var result = await ApiClass.updateRequest(
        "admin/userbanks/verify/" + id,
        true,
        formdata
      );
      if (result.data.status_code == 1) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.$refs.table.refresh();
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Rejected Requests</h4>
            <simplebar style="max-height: 600px">
              <div class="table-responsive">
                <b-table
                  :items="myProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <template #empty>
                    <div class="d-flex justify-content-center mb-3">
                      No Record Found
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <!-- <strong>Loading...</strong> -->
                    </div>
                  </template>

                  <template v-slot:cell(Name)="data">
                    <div v-if="data.item.user.name">
                      {{ data.item.user.name }}
                    </div>
                    <div v-else>N/A</div>
                  </template>
                  <template v-slot:cell(Username)="data">
                    <div v-if="data.item.user.user_name">
                      {{ data.item.user.user_name }}
                    </div>
                    <div v-else>N/A</div>
                  </template>

                  <template v-slot:cell(Email)="data">
                    <div v-if="data.item.user.email">
                      {{ data.item.user.email }}
                    </div>
                  </template>

                  <template v-slot:cell(Mobile)="data">
                    <div v-if="data.item.user.mobile">
                      {{ data.item.user.mobile }}
                    </div>
                  </template>

                  <template v-slot:cell(Document)="data">
                    <b-button
                      v-b-modal.modal-center
                      variant="white"
                      @click="currentRowData = data.item"
                      class="btn btn-primary btn-sm w-xs"
                      >View</b-button
                    >
                  </template>
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <b-modal
      id="modal-center"
      size="xl"
      centered
      title="Bank Verification"
      title-class="font-18"
      hide-footer
      v-if="currentRowData"
    >
      <b-form @submit="onSubmit($event, currentRowData.id)">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="exchange_setting_heading">
                <h3 class="fw-bold">Bank Detail</h3>
              </div>
              <!--Heading-->
            </div>
          </div>
          <div class="row m-0 py-4 mt-4 ps-0 list-group-item">
            <div class="col-md-12">
              <div class="row g-3 align-items-center justify-content-center">
                <div class="col-md-4">
                  <label for="inputAccount1" class="form-label label_fw"
                    >Alias:</label
                  >
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    class="form-control input_style_cus shadow-none"
                    id="inputAccount1"
                    :value="currentRowData.alias"
                    readonly
                  />
                </div>
                <!-- Alias -->
                <div class="col-md-4">
                  <label for="inputAccount1" class="form-label label_fw"
                    >Account Number:</label
                  >
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    class="form-control input_style_cus shadow-none"
                    id="inputAccount1"
                    placeholder="Enter Account Number"
                    :value="currentRowData.account_number"
                    readonly
                  />
                </div>
                <!-- Account Number  -->
                <div class="col-4">
                  <label for="inputAddress" class="form-label label_fw"
                    >IFSC Code/Swift Code:</label
                  >
                </div>
                <div class="col-8">
                  <input
                    type="text"
                    class="form-control input_style_cus shadow-none"
                    id="inputAddress"
                    placeholder="Enter IFSC Code"
                    :value="currentRowData.ifsc_code"
                    readonly
                  />
                </div>
                <!-- IFSC Code  -->
                <div class="col-md-4">
                  <label for="inputAccount" class="form-label label_fw"
                    >Account Type:</label
                  >
                </div>
                <div class="col-md-8">
                  <input
                    type="text"
                    class="form-control input_style_cus shadow-none"
                    id="inputAddress"
                    placeholder="Enter IFSC Code"
                    :value="currentRowData.account_type"
                    readonly
                  />
                </div>
                <!--Account Type-->
                <div class="col-md-8">
                  <div v-if="currentRowData.is_verify == 0">
                    <span>Reason : </span>
                    <span class="clr-red text-break">{{ currentRowData.remark }}</span>
                  </div>

                  <div
                    class="select_box"
                    :class="{
                      'form-group--error': $v.form.status.$error,
                    }"
                  >
                    <label for="">Status:</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      v-model.trim="$v.form.status.$model"
                      @change="
                        remarkShow = $v.form.status.$model == 0 ? true : false
                      "
                    >
                      <option value="1">Approved</option>
                      <option value="0">Rejected</option>
                    </select>
                    <div
                      class="error"
                      v-if="submitted && !$v.form.status.required"
                    >
                      Status is required
                    </div>
                  </div>

                  <!-- Identity Remark  -->
                  <div
                    v-show="remarkShow"
                    class="mt-4"
                    :class="{
                      'form-group--error': $v.form.remark.$error,
                    }"
                  >
                    <input
                      v-model.trim="$v.form.remark.$model"
                      class="form-control"
                      type="text"
                      placeholder="Remark"
                      aria-label="default input example"
                    />
                    <div
                      class="error"
                      v-if="submitted && !$v.form.remark.required"
                    >
                      Remark is required
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mt-5">
                  <button class="btn btn-success" type="submit">Save</button>
                </div>
                <!--save button-->
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>


<style scoped>
.front_image img,
.back_image img {
  width: 150px;
  height: 120px;
}
.succ_btn {
  float: right;
  clear: both;
  margin: 20px 0px;
}
.clr-red {
  color: red;
}
.label_fw {
  font-weight: 500;
}
.input_style_cus:focus {
  box-shadow: 0;
  border-color: rgb(170, 170, 170);
}
</style>